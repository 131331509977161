<template>
  <div>

  <div class="container">
    <div class="row ">
      <div class="col-12 align-content-center justify-content-center">
      <h1>Проект <br> {{project_name}}</h1>
      </div>
    </div>
  </div>


    <div class="row m-12 justify-content-center align-items-center">

      <b-dropdown class="btn-group mr-2 mt-2" variant="primary" text="Скачать">
        <b-dropdown-item @click="downloadFile('docx')">.docx</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="downloadFile('txt')">.txt</b-dropdown-item>
      </b-dropdown>

      </div>

    <div class="row m-12 justify-content-center align-items-center">

      <div class="col-11">
        <div class="table-responsive bg-white shadow rounded">
          <table class="table mt-2 mb-0 table-center" style="width:100%;">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Слушать</th>
              <th scope="col"> Текст</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(x,ix) in speech2Text">

              <td>{{ix+1}}</td>

              <td>
                <player :src="urlRadio(x.file_name)"/>
              </td>

              <td>
                <div v-html="x.text_transcribe"></div>
              </td>

            </tr>
            </tbody>
          </table>
        </div>
      </div>





    </div>



  </div>
</template>

<script>
import store from "@/store/store";
import {MessageCircleIcon,BookIcon,DownloadIcon} from "vue-feather-icons";
import player from '../player/player'

export default {
  components: {
    player,
    MessageCircleIcon, BookIcon, DownloadIcon,
  },
  name: "whisperHistory",
  data() {
    return {

      input_api: {
        user_id: 1,
        project_hash:'a',
      },

      tableShow:false,
      showStatus:false,
      status: {
        rows_in:0,rows_out:0,
      },
      token:'',
      excelResults:[],

      hash:'',
      project_name: '',
      speech2Text: [],
    }
  },

  mounted() {
    this.getHistory();
  },

  methods: {




    urlRadio(filename) {
      let url = '' + this.$hostnamejava + '/radio-s3/stream-file/whisper/' + filename
      return url
    },

    downloadFile(x) {

      this.input_api.user_id = store.state.user_id;
      this.input_api.project_hash = this.$route.params.hash;
      if (x==='docx') {
        this.axios({
          method: 'post',
          url: '' + this.$hostnamejava + '/whisper/download/docx-results',
          data: this.input_api,
          responseType: "blob",
        }).then(function (response) {
          const blob = new Blob(
              [response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
          const aEle = document.createElement('a');
          const href = window.URL.createObjectURL(blob);
          aEle.href = href;
          aEle.download = "result.docx";
          document.body.appendChild(aEle);
          aEle.click();
          document.body.removeChild(aEle);
          window.URL.revokeObjectURL(href);
        })
      } else {
        this.axios({
          method: 'post',
          url: '' + this.$hostnamejava + '/whisper/download/text-results',
          data: this.input_api,
          responseType: "blob",
        }).then(function (response) {
          const blob = new Blob(
              [response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
          const aEle = document.createElement('a');
          const href = window.URL.createObjectURL(blob);
          aEle.href = href;
          aEle.download = "result.txt";
          document.body.appendChild(aEle);
          aEle.click();
          document.body.removeChild(aEle);
          window.URL.revokeObjectURL(href);
        })
      }
    },


    getHistory() {

      this.input_api.user_id = store.state.user_id;
      this.input_api.project_hash = this.$route.params.hash;

      this.axios({
        method: 'post',
        //url: '' + this.$hostnamejava + '/whisper/get-results',
        url: '' + this.$hostnamejava + '/whisper/get-results',
        data: this.input_api,
      }).then((res) => {

        this.speech2Text = res.data.results;
        console.log(this.speech2Text)
      });

    },

  }
}
</script>

<style scoped>

</style>